import React from 'react';

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Alexant.ru
        </p>
      </header>
    </div>
  )
}

export default App;
